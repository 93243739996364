<template>
  <div class="height-100">
      <nuxt-link :to="{ path: localePath(`pre-order`) }" tag="a" class="pre-fix">
        <span class="aside__item__text text-uppercase" style="font-weight: bold">
          {{ $t('Pre order  ') }}
        </span>
      </nuxt-link>
    <LazyHydrate when-visible>
      <rates-and-currencies />
    </LazyHydrate>

    <div class="d-flex justify-content-start search-block">
      <img src="~/assets/images/search.svg" class="search-logo cursor-pointer" alt="search-icon" @click="performSearch">
      <input
        id="general_search"
        v-model="searchInput"
        autocomplete="off"
        type="text"
        :placeholder="$t('menu.search')"
        class="border-0"
        @keyup.enter="performSearch"
      >
      <img src="~/assets/images/search-icon.svg" class="search-icon cursor-pointer" alt="search-image-icon">
    </div>

    <section class="container">
      <div class="d-flex align-items-center justify-content-center">
        <div id="switcher" class="switch__input d-none" :class="{'checked': switchRoute}" />
        <div class="slider position-relative d-inline-block order-2 flex-shrink-0" @click="switchRouteHandler()"></div>
        <h3 class="order-1 switch__label cursor-pointer text-center gold-text" @click="switchRouteHandler()">{{ $t('basic.gold') }}</h3>
        <span class="order-3 switch__checked cursor-pointer text-center silver-text" @click="switchRouteHandler()">{{ $t('basic.silver') }}</span>
      </div>
      <home-collections/>
    </section>
    <!-- <nuxt /> -->

    <section class="most-view new-collection-block bg-transparent mt-0">
      <div class="container p-0">
        <h2 class="new-collection-block__title m-0 text-center">
          {{ $t('main.most_viewed') }}
        </h2>
        <LazyHydrate when-visible>
          <app-carousel :carousel-list="mostViewed" slider-id="slider_3" />
        </LazyHydrate>
      </div>
    </section>

    <section class="best-sales new-collection-block bg-transparent pt-0">
      <card-best-sales />

      <card-start-selling class="card-start-selling" />
    </section>

    <section class="recommended-sellers-block">
      <div class="container">
        <div class="text-center">
          <h2 class="recommended-sellers-block__title">
            {{ $t('main.recommended_sellers.title') }}
          </h2>
          <p class="recommended-sellers-block__description">
            {{ $t('main.recommended_sellers.descriptions') }}
          </p>

          <LazyHydrate when-visible>
            <div class="similar-items-container d-grid">
              <card-brand
                v-for="brand of recommendedSellers.slice(0, 3)"
                :id="brand.id"
                :key="brand.id"
                :brand-info="brand"
              />
            </div>
          </LazyHydrate>
          <div class="d-flex justify-content-center">
            <nuxt-link
              :to="{ path: localePath('/seller') }"
              tag="button"
              class="btn btn-outline shadow-none rounded-0 d-inline-flex align-items-center justify-content-center"
            >
              {{ $t('main.all_brands') }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </section>

    <card-small-sell-with-us />

    <WhyGoldencenter />
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import { mapState, mapActions } from 'vuex'
import Pagination from '~/core/mixins/pagination'
import { TIME_OUT_DEF } from '~/core/utils'

export default {
  components: {
    LazyHydrate,
    CardBrand: () => import('~/components/cards/card-brand.vue'),
    RatesAndCurrencies: () => import('~/components/tables/rates-and-currencies.vue'),
    CardStartSelling: () => import('~/components/cards/card-start-selling.vue'),
    AppCarousel: () => import('~/components/ui/app-carousel.vue'),
    CardSmallSellWithUs: () => import('~/components/cards/card-small-sell-with-us.vue'),
    HomeCollections: () => import('~/components/pages/home-collections.vue'),
    CardBestSales: () => import('~/components/cards/card-best-sales.vue'),
    WhyGoldencenter: () => import('~/components/WhyGoldencenter.vue')
  },
  mixins: [Pagination],
  data () {
    return {
      searchInput: '',
      isFocusSearch: false,
      showSearchView: false,
      isLoading: false,
      isSearch: false,
      showFix: false
    }
  },
  asyncData ({ store }) {
    return store.dispatch('main/list')
  },
  // async fetch () {
  //   this.switchRoute = await this.isShopping()
  // },
  head () {
    return {
      title: this.$t('meta.home.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('meta.home.description')
        },
        {
          hid: 'content',
          name: 'content',
          content: '<h1>Armenian Jewelry Marketplace in Gold Center</h1>'
        },
        {
          hid: 'name',
          name: 'name',
          content: 'Gold Center'
        },
        ...this.$socialMeta({
          url: 'organization',
          title: this.$t('meta.home.title'),
          site_name: 'Gold Center',
          description: this.$t('meta.home.description'),
          img: 'https://storage.goldcenter.am/frontend/default.jpg',
          locale: this.$i18n.locale,
          twitter: '@Gold Center',
          twitter_card: 'summary_large_image'
        })
      ],
      script: [
        {
          hid: 'schema',
          type: 'application/ld+json',
          json: {
            context: 'https://schema.org (https://schema.org/)',
            type: 'Organization',
            name: 'Gold Center',
            description: this.$t('meta.home.description'),
            url: 'https://develop.goldcentr.am (https://develop.goldcentr.am/)',
            logo: 'https://develop.goldcentr.am/wp-content/themes/goldcenter/images/logo.svg',
            sameAs: [
              'https://www.facebook.com/goldcenter',
              'https://twitter.com/goldcenter',
              'https://www.instagram.com/goldcenter',
              'https://www.linkedin.com/company/goldcenter'
            ],
            contactPoint: {
              type: 'ContactPoint',
              telephone: '+37411223344',
              contactType: 'customer service'
            },
            address: {
              type: 'PostalAddress',
              streetAddress: '123 Main St',
              addressLocality: 'Yerevan',
              postalCode: '0001',
              addressCountry: 'Armenia'
            }
          }
        }
      ]
    }
  },

  computed: {
    ...mapState({
      // list: state => state.collection.collections,
      mainInfo: state => state.main.mainInfo,
      bestSales: state => state.main.mainInfo.best_sales,
      mostViewed: state => state.main.mainInfo.most_viewed,
      newCollections: state => state.main.mainInfo.new_collections,
      recommendedSellers: state => state.main.mainInfo.recommended_sellers
    }),
    switchRoute () {
      return this.$store.state.siteMode.sitemode === 'silver'
    }
  },
  created () {
    // Example: Fetch most viewed items of type 'electronics' on component creation
    this.$store.dispatch('list', { type: this.$store.state.siteMode.sitemode })
  },
  // watch: {
  //   switchRoute: {
  //     handler (val) {
  //       if (val) {
  //         this.$router.push({ path: this.localePath('/shopping-centers') })
  //       } else {
  //         this.$router.push({ path: this.localePath('/') })
  //       }
  //     }
  //   }
  // },
  mounted () {
    if (process.browser) {
      setTimeout(() => {
        this.scrollToElement()
        this.scrollToElementSeller()
      }, TIME_OUT_DEF)
    }
    this.getUserGroups()
  },
  beforeDestroy () {
    if (process.browser) {
      window.removeEventListener('resize', this.videoListener)
    }
  },
  methods: {
    ...mapActions({
      getCurrentUser: 'auth/getCurrentUser'
    }),
    loadMore () {
      if (this.showLoadMore() || !this.productLists.length) {
        return
      }
      this.isLoading = true
      this.page += 1
      this.get({ loadMore: true })
    },

    performSearch () {
      if (this.searchInput.trim() !== '') {
        this.$router.push({ path: '/search', query: { q: this.searchInput } })
      }
    },

    showLoadMore () {
      return this.totalCount === this.productLists?.length
    },
    switchRouteHandler () {
      if (!this.switchRoute) {
        this.$cookies.set('siteMode', 'silver')
        this.$store.commit('siteMode/setMode', 'silver')
      } else {
        this.$cookies.set('siteMode', 'gold')
        this.$store.commit('siteMode/setMode', 'gold')
      }
    },
    async getUserGroups () {
      await this.getCurrentUser()
      if (!this.currentUser) { return }
      try {
        const ugroup = await this.$axios.$get('/preorder/prices', {
          params: {
            user_id: this.currentUser.user_id
          }
        })
        if (ugroup) {
          this.showFix = true
        }
      } catch (error) {
        console.error('Error fetching pending orders:', error)
      }
    },
    async scrollToElementSeller () {
      if (process.browser) {
        const sellerId = this.$cookies.get('sellerId')
        if (sellerId) {
          const element = document.getElementById(sellerId)
          if (element) {
            element.scrollIntoView({
              block: 'center',
              inline: 'nearest',
              behavior: 'auto'
            })
            await this.$cookies.remove('sellerId')
          }
        }
      }
    },
    isShopping () {
      return this.$route.path === this.localePath('/shopping-centers')
    }
  }
}
</script>

<style>
@import '~/assets/css/pages/home.css';
</style>
<style scoped>
.switch__input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch__input.checked + .slider {
  background-color: #B8BCC0;
}
.switch__input + .slider {
  background-color: #D8C273;
}
.switch__input.checked + .slider:before {
  transform: translateX(15px);
}
.switch__label,
.switch__checked {
  font-size: 32px;
  margin: 0;
}
.switch-sellers-block{
  padding: 20px 0;
}
@media all and (max-width: 766.9px) {
  .switch__label,
  .switch__checked {
    font-size: 16px;
    line-height: 1;
  }
  .switch__input.checked + .slider:before {
    transform: translateX(14px);
  }
}
.most-view {
  /* border-bottom: 1px solid rgba(112,122,132,0.10196); */
  border-top: 1px solid rgba(112,122,132,0.10196);
  margin-bottom: 30px;
  padding-bottom: 0;
}

.screen-container {
  margin-bottom: 60px;
  object-fit: contain !important;
}
.play-btn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 80px;
}
.search-block {
  border: 2px solid rgba(196, 196, 196, 1);
  border-radius: 20px;
  margin: 0 auto;
  max-width: 400px;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
  align-items: center;
  padding-left: 16px;
  margin-bottom: 30px;
}
.search-block img.search-logo {
  padding-right: 9px;
  border-right: 2px solid rgba(196, 196, 196, 1);
  margin-right: 11px;
  width: 40px;
}
.search-block input {
  color: rgba(123, 123, 123, 1);
}

.search-block img.search-icon {
  position: absolute;
  right: 10px;
  width: 32px;
  height: 27px;
  opacity: 0.7;
}

.pre-fix {
  display:none;
  justify-content:center;
  align-items:center;
  background: #00323F;
  width: 60%;
  height: 47px;
  color:#fff;
  margin: 23px auto;
}

.pre-fix img {

}

@media all and (max-width: 575px) {
  .pre-fix {
    display: flex;
  }
}

</style>
